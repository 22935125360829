// scss-docs-start grid-breakpoints
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1400px,
        xxl: 1800px
);

// Font Weight
$fw-medium: 500;
$font-weight-semibold: 600;
$headings-font-family:        "Montserrat", sans-serif;

  // scss-docs-start gray-color-variables
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

// scss-docs-start color-variables
$blue:    #4067a4; //#293f61;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #12a703; //#198754;
$teal:    #20c997;
$cyan:    #0dcaf0;

// scss-docs-start theme-color-variables
$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $orange;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;


// Vertical Sidebar
$sidebar-bg: $primary;
//$sidebar-gradient: url('../images/bg-pattern.png'), linear-gradient(90deg,#22395b,#4067a4);
$sidebar-gradient: linear-gradient(60deg,#22395b,#4067a4);
$sidebar-width: 230px;
$sidebar-menu-item-color: #a6b0cf;
$sidebar-menu-sub-item-color: #545a6d;
$sidebar-menu-item-icon-color: #6a7187;
$sidebar-menu-item-hover-color: #ffffff;
$sidebar-menu-item-active-color: #ffffff;
$sidebar-width: 250px;
$sidebar-collapsed-width: 70px;
$sidebar-width-sm: 160px;


// Body
//
// Settings for the `<body>` element.

$body-bg:                   #f7f7f7;

//Navbar
$navbar-light-color:                rgba($white, 1);
$navbar-light-hover-color:          rgba($white, .9);
$navbar-light-active-color:         rgba($white, .9);
$navbar-light-disabled-color:       rgba($white, .3);
$navbar-light-toggler-border-color: rgba($white, .1);

//Button

$border-radius: 3px;
$btn-border-radius-sm: 3px;
$btn-border-radius: 3px;
