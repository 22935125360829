.bu-code {
}

.bu-section {
  padding: 40px 0;
  h1, h2, h3 {
    margin-bottom: 10px;
    span {
      font-weight: 400;
    }
  }

  h2 {
    font-size: 2.2rem;
  }
}

.site-header {
  margin-top: -50px;
  position: relative;
  min-height: 230px;

  .wave-shape {
    position: absolute;
    bottom: -3px;
  }
  .hero-content-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    position: relative;
    top: 20px;
  }

}

.bu-heading {
  padding-left: 20px;

  span {
    display: inline-block;
    width: 4px;
    height: 1.55rem;
  }

  .tag-h2 {
    span {
      height: 1.55rem;
    }
  }

  .tag-h1 {
    span {
      height: 1.63rem;
    }
  }

  .tag-h3,
  .tag-h4 {
    span {
      height: .9rem;
    }
  }


  h1, h2, h3, h4 {
    display: inline-block;
    margin: 15px 0;
  }

  a {
    font-size: 1rem;
    text-decoration: none;

    span {
      font-size: 0.8rem;
      transform: translateX(0);
      transition: transform 500ms;
    }

    &:hover {
      span {
        transform: translateX(5px);
      }
    }
  }
}

.bu-section.background-img {
  padding: 75px 0;
   h1, h2, h3 {
     color: #fff;
    span {
      font-weight: 400;
    }
  }

  p {
    color: #fff;
  }
}

.taxonomy > span {
   display: inline-block;
   width: 3px;
   height: 11px;
   background: #fff;
}

.overlay-snippet  {
  a {
    text-decoration: none;
    color: #fff;
  }

  a:hover {
    color: #eee;
  }

  img {
    height: 357px;
    object-fit: cover;
  }

  .taxonomy > span {
    background: $primary;
  }

  .card-img-overlay {
    background-image: linear-gradient(0deg, rgba(0,0,0,0.912) 0%, rgba(0,0,0,0.173) 39%, rgba(0,0,0,0) 100%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
    h3 {
      color: #fff;
      font-size: $h6-font-size;
    }
  }

  margin-bottom: 5px;
  .excerpt {
    display: none;
  }
}

.one-line-snippet,
.featured-posts-list {
  text-align: left;
  margin: 10px 0;

  .label {
    display: inline-block;
    font-weight: 600;
    margin-right: 5px;
  }
}

.featured-snippet  {
  border: 0;

  .card-title {
     a {
       text-shadow: none;
       text-decoration: none;
     }
  }

  .card-body {
    padding-right: 5px;
    padding-left: 5px;
  }


  img {
    height: 200px;
    object-fit: cover;
  }

  .excerpt {
    margin-bottom: 10px
  }
  .taxonomy {
    span {
      background: $secondary;
    }
  }

  .card-body {
    h3 {
      font-size: $h5-font-size;
    }
  }
}

.featured-snippets-qa {
  .accordion-body {
    background-color: #fff;
  }
}

.resource-snippet {
  background-color: #fff;
  padding: 10px;
  margin-bottom:  10px;

  .card-header {
    display: flex;
    border: 0;
    background: transparent;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      margin-right: 15px;
      object-fit: cover;
    }

    .meta {
      display: block;
      h3 {
        display: inline-block;
        font-size: 1.1rem;
        margin: 0;
        a {
          color: #000;
        }
      }

    }

  }

  .excerpt {
    font-size: 0.9em;
  }

  .tags,
  .labels {
    a {
      margin-right: 5px;
      border: 1px solid #ccc;
      border-radius: 10px;
      padding: 0 10px;
      text-decoration: none;
      font-size: 0.9em;
    }

    a:last-child {
      margin-right: 0;
    }
  }

  .tags {
    margin-top: 10px;
  }

  .labels {
    a {
      background: #9efda7;
      border-color: #9efda7;
      margin-right: 5px;
    }
  }
}


.featured-posts-rectangle {
  .excerpt {
    display: block;
  }

  .overlay-snippet {
    h3 {
      font-size: $h3-font-size;
    }

    img {
      height: auto;
    }
  }
}

.featured-snippets-columns {
  .row > div {
    display: flex;
  }

  .featured-snippet {
    margin-bottom:  10px;
    width: 100%;
  }

  .col-lg-6 {
    img {
      height: 380px;
    }
  }

  .col-lg-3 {
    img {
      height: 200px;
    }
  }

  .col-lg-12 {
    .featured-snippet {
      img {
        height: 500px;
      }
    }
  }

}

.featured-snippets-columns,
.featured-posts-rectangle
{
  &.container-fluid {
    img {
      height: 300px;
    }
  }
}

.bu-related-posts {
  margin: 20px 0;
  h3, h4, h5 {
    margin: 0;
    font-size: $h5-font-size;
  }
}

.featured-posts-lead {

  .section {
    img {
      height: 175px;
    }

    .taxonomy > span {
      background: yellowgreen;
    }
  }

  .lead-snippet {
    img {
      height: 357px;
    }

    .excerpt {
      display: block;
    }
    h3 {
      font-size: $h3-font-size;
    }
  }

  &.container {
    .section {
      img {
        height: 200px;
      }
    }

    .lead-snippet {
      img {
        height: 406px;
      }
    }
  }

  &.container-fluid {
    .section {
      img {
        height: 220px;
      }
    }

    .lead-snippet {
      img {
        height: 446px;
      }
    }
  }
}

.content {

  .bu-section,
  .bu-heading {
    padding: 0;
  }

  .bu-heading {
    span {
      height: 1.1rem;
    }
  }


  .resource-snippet {
    .card-header {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        margin-bottom: 15px;
      }
    }
  }

  .featured-snippet {
    img {
      height: 180px;
    }
  }

  .col-lg-12 {
    .featured-snippet {
      img {
        height: 500px;
      }
    }
  }

  .featured-posts-lead {
    .section {
      img {
        height: 150px;
      }
    }

    .lead-snippet {
      img {
        height: 307px;
      }
    }
  }

  .col-lg-6  {
    .featured-snippet {
      img {
        height: 240px;
      }
    }
  }

  .col-lg-3  {
    .featured-snippet {

      h3 {
        font-size: $h5-font-size;
      }

      img {
        height: 200px;
      }
    }
  }
}


@media(min-width: 300px) {
  .site-header {
    min-height: 220px;
  }

  .content,
  .bu-section {
    .featured-posts-lead {
      .section {
        img {
          height: 250px;
        }
      }
    }

    .featured-snippets-columns {
      .col-12 {
        img {
          height: 250px;
        }
      }
    }
  }

  .content {
    .featured-snippets-columns {
      .col-12 {

        a {
          text-decoration: none;
        }

        img {
          height: auto;
        }
      }
    }
  }
}

@media(min-width: 768px) {
  .site-header,
  .hero-content-left {
    min-height: 300px;
  }

  .content,
  .bu-section {
    .featured-posts-lead {
      .section,
      .lead-snippet {
        img {
          height: 450px;
        }
      }
    }

    .featured-snippets-columns {
      .col-12 {
        img {
          height: 350px;
        }
      }
    }

    .featured-snippets-columns {
      .col-md-6 {
        img {
          height: 250px;
        }
      }
    }

    .featured-posts-rectangle {
      .col-12 {
        .overlay-snippet {
          img {
            height: 500px;
          }
        }
      }
    }
  }

  .content {
    .featured-snippets-columns {
      .col-12 {

        a {
          text-decoration: none;
        }

        img {
          height: auto;
        }
      }
    }
  }

}

@media(min-width: 980px) {
  .content,
  .bu-section {
    .featured-posts-lead {
      .lead-snippet {
        img {
          height: 410px;
        }
      }

      .section {
        img {
          height: 200px;
        }
      }
    }

    .featured-snippets-columns {
      .col-lg-3 {
        img {
          height: 200px;
        }
      }
    }
  }
}


@media(min-width: 1366px) {
  .site-header,
  .hero-content-left {
    min-height: 400px;
  }
}


@media(min-width: 1920px) {
  .site-header,
  .hero-content-left {
    min-height: 480px;
  }
}